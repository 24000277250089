import { render, staticRenderFns } from "./Salary_distribution.vue?vue&type=template&id=43d3ac5f&scoped=true"
import script from "./Salary_distribution.vue?vue&type=script&lang=js"
export * from "./Salary_distribution.vue?vue&type=script&lang=js"
import style0 from "./Salary_distribution.vue?vue&type=style&index=0&id=43d3ac5f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d3ac5f",
  null
  
)

export default component.exports